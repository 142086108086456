<script setup>
import GenericButton from '@/Components/GenericButton.vue';
import { computed, onMounted, ref } from 'vue';
import { Link } from "@inertiajs/vue3";

const props = defineProps({
   isRegionPage: {
      type: Boolean,
      default: true
   },
   images: {
      type: Array,
      default: []
   },
   region: Object
})
// const appUrl = ref(import.meta.env.VITE_APP_URL);
const bgImage = ref(null);
const reactiveImageIndex = ref(0);
const selectImageIndex = ref(0);

onMounted(() => {
   loadFirstImage();
})
const loadFirstImage = () => {
   let regionImage = props.region.region_gallery ?? [];
    if (regionImage.length <= 0) {
      bgImage.value = null;
      return;
   }
   const firstImageUrl = props.region.region_gallery[0].url;
   bgImage.value = firstImageUrl;
};

const selectCurrentImage = (galleryIndex) => {
   const imagePath = props.region.region_gallery[galleryIndex].url;
   bgImage.value = imagePath;
   selectImageIndex.value = galleryIndex;
   reactiveImageIndex.value = galleryIndex;
};

const nextImage = () => {
   const galleryLength = props.region.region_gallery.length;
   const newIndex =  (selectImageIndex.value + 1) % galleryLength;
   selectImageIndex.value = newIndex
   bgImage.value = props.region.region_gallery[newIndex].url;
   reactiveImageIndex.value = newIndex;
};


const prevImage = () => {
   const galleryLength = props.region.region_gallery.length;
   const newIndex = (selectImageIndex.value - 1 + galleryLength) % galleryLength;
   selectImageIndex.value =(selectImageIndex.value - 1 + galleryLength) % galleryLength;
   bgImage.value = props.region.region_gallery[newIndex].url;
   reactiveImageIndex.value = newIndex;
};

const regionProfile = computed(() => {
   let profile = props.region.profiles ?? [];
   if(profile.length <= 0) {
      return '';
   }
   const pr = props.region.profiles.find(profile => profile.title === 'description');
   return pr ? pr.text : '';
});

//import TextInput from '@/Components/TextInput.vue';
</script>

<template>
  <div class="flex flex-col w-full gap-6 pb-3 overflow-auto" id="feed-right">

   <!-- header -->
   <div class="flex items-center justify-between" v-if="!isRegionPage">
   <h2 class="text-2xl font-bold text-primary"> {{ $t('Region') }} </h2>
	<!--  <span class="text-xs font-bold uppercase cursor-pointer text-disabled">{{ $t('See details') }}</span> -->

   </div>


   <div class="">
   <div class="relative overflow-hidden rounded-xl">
      <img v-if="bgImage " :src="bgImage" :alt="regionindex" class="w-full ">
      <img v-else src='/assets/img/not-found.png' class="w-full border" />

      <!-- cursor/pagination -->
      <div v-if="region?.region_gallery?.length > 0"
         class="absolute flex items-center gap-3 px-3 py-1 text-white bg-gray-800 rounded-lg right-4 bottom-4 w-fit">

         <svg @click="prevImage" class="cursor-pointer" width="6" height="12"
            viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
               d="M4.37226 11.25C4.14531 11.2508 3.93023 11.1487 3.78726 10.9725L0.164757 6.47248C-0.0627313 6.19573 -0.0627313 5.79673 0.164757 5.51998L3.91476 1.01998C4.17985 0.701035 4.65331 0.657383 4.97226 0.92248C5.2912 1.18758 5.33485 1.66104 5.06976 1.97998L1.71726 5.99998L4.95726 10.02C5.14453 10.2448 5.18398 10.5579 5.05832 10.8222C4.93265 11.0864 4.66481 11.2534 4.37226 11.25Z"
               fill="white"></path>
         </svg>
         <span>{{ region?.region_gallery?.length > 0 ? reactiveImageIndex + 1 : 0  }}/{{ region?.region_gallery?.length }}</span>
         <svg @click="nextImage" class="cursor-pointer" width="7" height="12"
            viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
               d="M1.49937 11.25C1.32413 11.2503 1.1543 11.1893 1.01937 11.0775C0.865989 10.9503 0.769508 10.7673 0.751217 10.5689C0.732925 10.3705 0.794325 10.173 0.921869 10.02L4.28187 5.99996L1.04187 1.97246C0.916041 1.81751 0.857168 1.6188 0.878283 1.42032C0.899398 1.22184 0.99876 1.03996 1.15437 0.914959C1.31124 0.776929 1.51859 0.710602 1.72645 0.731958C1.93431 0.753313 2.12384 0.860415 2.24937 1.02746L5.87187 5.52746C6.09936 5.80421 6.09936 6.20321 5.87187 6.47996L2.12187 10.98C1.96927 11.164 1.73805 11.2643 1.49937 11.25Z"
               fill="white"></path>
         </svg>

      </div>

   </div>
   </div>

   <!-- image list -->
   <div class="flex flex-shrink-0 gap-4 overflow-hidden">
      <div @click="selectCurrentImage(galleryIndex)"
         v-if="region?.region_gallery?.length > 0"
         v-for="(media, galleryIndex) in region?.region_gallery" :key='galleryIndex'
         class="flex w-16 h-16 overflow-hidden cursor-pointer shrink-0 rounded-xl"
         :class="galleryIndex === selectImageIndex ? 'border-2 border-blue-800' : 'opacity-50'">
         <img v-if="media?.url !== null" :src="media?.url" alt="">
      </div>
      <div v-else class="flex w-16 h-16 overflow-hidden opacity-50 shrink-0 rounded-xl">
         <img src='/assets/img/not-found.png' alt="">
         </div>
   </div>

    <div class="grid gap-4">
      <Link :href="route('regions.show', {organization:  region.slug })" >
         <h2 class="text-lg font-bold text-primary">{{ region.name }}</h2>
      </Link>
      <!-- rating -->
      <div class="flex gap-2.5">
        <div class="flex gap-1">
          <div class="flex">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.6336 16.4998C13.5004 16.5004 13.3689 16.4689 13.2503 16.4082L9.00029 14.1832L4.75029 16.4082C4.61228 16.4807 4.45669 16.5131 4.30119 16.5017C4.14569 16.4902 3.99652 16.4354 3.87065 16.3433C3.74477 16.2513 3.64724 16.1258 3.58914 15.9811C3.53103 15.8365 3.51469 15.6784 3.54196 15.5248L4.37529 10.8332L0.941957 7.49984C0.834838 7.39295 0.75885 7.2589 0.722147 7.11209C0.685444 6.96528 0.689411 6.81124 0.733624 6.66651C0.781924 6.51841 0.870771 6.38681 0.990082 6.28664C1.10939 6.18648 1.25439 6.12177 1.40862 6.09984L6.15862 5.40818L8.25029 1.13318C8.31853 0.992285 8.42507 0.873463 8.55772 0.790322C8.69036 0.707182 8.84374 0.663086 9.00029 0.663086C9.15684 0.663086 9.31022 0.707182 9.44286 0.790322C9.57551 0.873463 9.68205 0.992285 9.75029 1.13318L11.867 5.39984L16.617 6.09151C16.7712 6.11343 16.9162 6.17815 17.0355 6.27831C17.1548 6.37847 17.2437 6.51007 17.292 6.65818C17.3362 6.80291 17.3401 6.95694 17.3034 7.10376C17.2667 7.25057 17.1907 7.38461 17.0836 7.49151L13.6503 10.8248L14.4836 15.5165C14.5134 15.6727 14.4978 15.8342 14.4387 15.9819C14.3797 16.1295 14.2796 16.2572 14.1503 16.3498C13.9994 16.4556 13.8177 16.5083 13.6336 16.4998Z"
                fill="#FFAB00" />
            </svg>
          </div>
          <div class="flex">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.6336 16.4998C13.5004 16.5004 13.3689 16.4689 13.2503 16.4082L9.00029 14.1832L4.75029 16.4082C4.61228 16.4807 4.45669 16.5131 4.30119 16.5017C4.14569 16.4902 3.99652 16.4354 3.87065 16.3433C3.74477 16.2513 3.64724 16.1258 3.58914 15.9811C3.53103 15.8365 3.51469 15.6784 3.54196 15.5248L4.37529 10.8332L0.941957 7.49984C0.834838 7.39295 0.75885 7.2589 0.722147 7.11209C0.685444 6.96528 0.689411 6.81124 0.733624 6.66651C0.781924 6.51841 0.870771 6.38681 0.990082 6.28664C1.10939 6.18648 1.25439 6.12177 1.40862 6.09984L6.15862 5.40818L8.25029 1.13318C8.31853 0.992285 8.42507 0.873463 8.55772 0.790322C8.69036 0.707182 8.84374 0.663086 9.00029 0.663086C9.15684 0.663086 9.31022 0.707182 9.44286 0.790322C9.57551 0.873463 9.68205 0.992285 9.75029 1.13318L11.867 5.39984L16.617 6.09151C16.7712 6.11343 16.9162 6.17815 17.0355 6.27831C17.1548 6.37847 17.2437 6.51007 17.292 6.65818C17.3362 6.80291 17.3401 6.95694 17.3034 7.10376C17.2667 7.25057 17.1907 7.38461 17.0836 7.49151L13.6503 10.8248L14.4836 15.5165C14.5134 15.6727 14.4978 15.8342 14.4387 15.9819C14.3797 16.1295 14.2796 16.2572 14.1503 16.3498C13.9994 16.4556 13.8177 16.5083 13.6336 16.4998Z"
                fill="#FFAB00" />
            </svg>
          </div>
          <div class="flex">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.6336 16.4998C13.5004 16.5004 13.3689 16.4689 13.2503 16.4082L9.00029 14.1832L4.75029 16.4082C4.61228 16.4807 4.45669 16.5131 4.30119 16.5017C4.14569 16.4902 3.99652 16.4354 3.87065 16.3433C3.74477 16.2513 3.64724 16.1258 3.58914 15.9811C3.53103 15.8365 3.51469 15.6784 3.54196 15.5248L4.37529 10.8332L0.941957 7.49984C0.834838 7.39295 0.75885 7.2589 0.722147 7.11209C0.685444 6.96528 0.689411 6.81124 0.733624 6.66651C0.781924 6.51841 0.870771 6.38681 0.990082 6.28664C1.10939 6.18648 1.25439 6.12177 1.40862 6.09984L6.15862 5.40818L8.25029 1.13318C8.31853 0.992285 8.42507 0.873463 8.55772 0.790322C8.69036 0.707182 8.84374 0.663086 9.00029 0.663086C9.15684 0.663086 9.31022 0.707182 9.44286 0.790322C9.57551 0.873463 9.68205 0.992285 9.75029 1.13318L11.867 5.39984L16.617 6.09151C16.7712 6.11343 16.9162 6.17815 17.0355 6.27831C17.1548 6.37847 17.2437 6.51007 17.292 6.65818C17.3362 6.80291 17.3401 6.95694 17.3034 7.10376C17.2667 7.25057 17.1907 7.38461 17.0836 7.49151L13.6503 10.8248L14.4836 15.5165C14.5134 15.6727 14.4978 15.8342 14.4387 15.9819C14.3797 16.1295 14.2796 16.2572 14.1503 16.3498C13.9994 16.4556 13.8177 16.5083 13.6336 16.4998Z"
                fill="#FFAB00" />
            </svg>
          </div>
          <div class="flex">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.6336 16.4998C13.5004 16.5004 13.3689 16.4689 13.2503 16.4082L9.00029 14.1832L4.75029 16.4082C4.61228 16.4807 4.45669 16.5131 4.30119 16.5017C4.14569 16.4902 3.99652 16.4354 3.87065 16.3433C3.74477 16.2513 3.64724 16.1258 3.58914 15.9811C3.53103 15.8365 3.51469 15.6784 3.54196 15.5248L4.37529 10.8332L0.941957 7.49984C0.834838 7.39295 0.75885 7.2589 0.722147 7.11209C0.685444 6.96528 0.689411 6.81124 0.733624 6.66651C0.781924 6.51841 0.870771 6.38681 0.990082 6.28664C1.10939 6.18648 1.25439 6.12177 1.40862 6.09984L6.15862 5.40818L8.25029 1.13318C8.31853 0.992285 8.42507 0.873463 8.55772 0.790322C8.69036 0.707182 8.84374 0.663086 9.00029 0.663086C9.15684 0.663086 9.31022 0.707182 9.44286 0.790322C9.57551 0.873463 9.68205 0.992285 9.75029 1.13318L11.867 5.39984L16.617 6.09151C16.7712 6.11343 16.9162 6.17815 17.0355 6.27831C17.1548 6.37847 17.2437 6.51007 17.292 6.65818C17.3362 6.80291 17.3401 6.95694 17.3034 7.10376C17.2667 7.25057 17.1907 7.38461 17.0836 7.49151L13.6503 10.8248L14.4836 15.5165C14.5134 15.6727 14.4978 15.8342 14.4387 15.9819C14.3797 16.1295 14.2796 16.2572 14.1503 16.3498C13.9994 16.4556 13.8177 16.5083 13.6336 16.4998Z"
                fill="#FFAB00" />
            </svg>
          </div>
          <div class="flex">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.6336 16.4998C13.5004 16.5004 13.3689 16.4689 13.2503 16.4082L9.00029 14.1832L4.75029 16.4082C4.61228 16.4807 4.45669 16.5131 4.30119 16.5017C4.14569 16.4902 3.99652 16.4354 3.87065 16.3433C3.74477 16.2513 3.64724 16.1258 3.58914 15.9811C3.53103 15.8365 3.51469 15.6784 3.54196 15.5248L4.37529 10.8332L0.941957 7.49984C0.834838 7.39295 0.75885 7.2589 0.722147 7.11209C0.685444 6.96528 0.689411 6.81124 0.733624 6.66651C0.781924 6.51841 0.870771 6.38681 0.990082 6.28664C1.10939 6.18648 1.25439 6.12177 1.40862 6.09984L6.15862 5.40818L8.25029 1.13318C8.31853 0.992285 8.42507 0.873463 8.55772 0.790322C8.69036 0.707182 8.84374 0.663086 9.00029 0.663086C9.15684 0.663086 9.31022 0.707182 9.44286 0.790322C9.57551 0.873463 9.68205 0.992285 9.75029 1.13318L11.867 5.39984L16.617 6.09151C16.7712 6.11343 16.9162 6.17815 17.0355 6.27831C17.1548 6.37847 17.2437 6.51007 17.292 6.65818C17.3362 6.80291 17.3401 6.95694 17.3034 7.10376C17.2667 7.25057 17.1907 7.38461 17.0836 7.49151L13.6503 10.8248L14.4836 15.5165C14.5134 15.6727 14.4978 15.8342 14.4387 15.9819C14.3797 16.1295 14.2796 16.2572 14.1503 16.3498C13.9994 16.4556 13.8177 16.5083 13.6336 16.4998Z"
                fill="#919EAB" />
            </svg>

          </div>
        </div>
        <div class="text-sm text-disabled">(11.78k reviews)</div>
      </div>

      <div class="flex items-center gap-3 mb-2" v-if="region && !isRegionPage">
        <!-- <h5 class="text-lg font-bold text-disabled">{{ $t('Average Salary') }}</h5> -->
        <!-- <h6 class="text-lg font-bold text-primary">22 CHF/hour</h6> -->
      </div>
      <!-- <p class="pb-6 border-b border-[#919EAB33] text-sm text-secondary truncate"> -->
      <p class="w-full text-sm truncate text-secondary" v-if="region">
        {{ regionProfile }}
      </p>
    </div>

    <GenericButton
      v-if="!isRegionPage"
      is-link="true"
      :to="route('regions.show', {organization:  region.slug })"
      class="bg-[#212B36] border text-white text-sm font-bold transition-all w-full flex items-center gap-2 justify-center rounded-lg px-4 py-3">{{ $t('See more') }}</GenericButton>

      <div class="flex flex-wrap justify-between w-full gap-4 pb-6 border-b border-[#919EAB33]" v-if="isRegionPage">
         <GenericButton
            :isLink="true"
            type="button"
            :text="$t('Open positions')"
            theme="inherit-soft"
            class="hover:text-white hover:bg-primary"
            :to="route('regions.show',{organization : region.slug, openPoistion : 'openPosition'})"
            />
         <GenericButton
            :isLink="true"
            :text="$t('Region page')"
            :to="route('regions.show', {organization:  region.slug })"
            class="text-white border hover:text-primary bg-primary hover:bg-white hover:border-secondary-8"
            />
      </div>
  </div>
</template>

<style scoped>
/* #feed-right {

  position: fixed;
  right: 40px;
  top: 88px;
  background: white;

} */
</style>
